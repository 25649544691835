/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

/*--------------------------------------------------------------------------------------------------
  Colors
--------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------------
  Fonts
--------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------------
  Breakpoints
--------------------------------------------------------------------------------------------------*/
html {
  box-sizing: border-box;
  font-size: 10px; }

*, *::before, *::after {
  box-sizing: inherit; }

body {
  background: #eaeaea;
  color: #373a3c;
  font-family: 'Lato';
  font-size: 1.6rem;
  line-height: 1.45; }

img {
  height: auto;
  max-width: 100%; }

h1, h2, h3, h4, h5, h6, p, table, ol, ul {
  margin: 0 0 1.6rem;
  padding: 0; }

h1, h2, h3, h4, h5, h6 {
  line-height: 1.2; }

ol, ul {
  margin-left: 2.4rem; }
  nav ol, nav ul {
    list-style: none;
    margin: 0; }
  ol ol, ol ul, ul ol, ul ul {
    margin-bottom: 0; }

a {
  color: #27a570;
  transition: color 300ms; }
  a:hover {
    color: #145338; }

strong {
  font-weight: 700; }

code {
  font-family: Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New; }

hr {
  background: #ddd;
  border: none;
  clear: both;
  height: 1px;
  margin: 1.6rem 0 3.2rem;
  padding: 0; }

table {
  border-collapse: collapse;
  width: 100%; }

.container {
  margin: 0 auto;
  max-width: 92rem;
  width: 85%; }

#root {
  min-height: 100vh;
  position: relative; }

#app-inner {
  padding-top: 9.2rem; }

#app-loading {
  color: #999;
  text-align: center;
  text-transform: uppercase; }
  #app-loading #loading-icon {
    margin: 0 auto;
    position: relative;
    width: 6rem; }
    #app-loading #loading-icon::before {
      content: '';
      display: block;
      padding-top: 100%; }
    #app-loading #loading-icon svg {
      bottom: 0;
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%; }

nav #loading-icon {
  margin: 0 auto;
  position: relative;
  width: 3rem; }
  nav #loading-icon::before {
    content: '';
    display: block;
    padding-top: 100%; }
  nav #loading-icon svg {
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%; }

* {
  outline: none; }

button,
input[type="button"],
input[type="submit"],
select,
option {
  appearance: none; }

label {
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: 400;
  text-transform: uppercase; }

button,
input[type="button"],
input[type="submit"],
.button {
  border: 1px solid transparent;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 1.2rem 2.4rem;
  text-decoration: none;
  text-transform: uppercase; }
  button:disabled,
  input[type="button"]:disabled,
  input[type="submit"]:disabled,
  .button:disabled {
    background: #999 !important;
    opacity: 0.5;
    pointer-events: none; }
  button.button-primary,
  input[type="button"].button-primary,
  input[type="submit"].button-primary,
  .button.button-primary {
    background: #27a570;
    color: #fff;
    transition: background 300ms; }
    button.button-primary:hover,
    input[type="button"].button-primary:hover,
    input[type="submit"].button-primary:hover,
    .button.button-primary:hover {
      background: #1d7c54; }
  button.button-block,
  input[type="button"].button-block,
  input[type="submit"].button-block,
  .button.button-block {
    display: block;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    width: 100%; }

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
input:not([type]),
textarea,
select,
select[multiple] {
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: none;
  display: block;
  font-family: inherit;
  font-size: inherit;
  line-height: normal;
  margin-bottom: 0;
  padding: 1.2rem;
  width: 100%; }
  input[type="color"]:read-only,
  input[type="date"]:read-only,
  input[type="datetime"]:read-only,
  input[type="datetime-local"]:read-only,
  input[type="email"]:read-only,
  input[type="month"]:read-only,
  input[type="number"]:read-only,
  input[type="password"]:read-only,
  input[type="search"]:read-only,
  input[type="tel"]:read-only,
  input[type="text"]:read-only,
  input[type="time"]:read-only,
  input[type="url"]:read-only,
  input[type="week"]:read-only,
  input:not([type]):read-only,
  textarea:read-only,
  select:read-only,
  select[multiple]:read-only {
    background: #f7f7f7;
    cursor: default; }

#site-header {
  background: #fff;
  box-shadow: 0 0.25rem 0.5rem #ddd;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 90; }
  #site-header .inner {
    padding: 0 2rem; }
    #site-header .inner::after {
      clear: both;
      content: "";
      display: block; }
  #site-header .brand {
    display: block;
    float: left;
    height: 6rem;
    overflow: hidden;
    padding: 1rem 0;
    width: 4.2rem; }
    #site-header .brand img {
      display: block; }
  #site-header nav {
    float: right; }
    #site-header nav ol {
      font-size: 0;
      vertical-align: middle; }
    #site-header nav li {
      display: inline-block;
      font-size: 1.4rem;
      vertical-align: middle; }
      #site-header nav li.nav-loading {
        margin-right: 2rem; }
        #site-header nav li.nav-loading .loading-icon {
          position: relative;
          height: 6rem;
          width: 4rem; }
        #site-header nav li.nav-loading svg {
          bottom: 0;
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%; }
    #site-header nav a,
    #site-header nav .nav-link-button {
      background: transparent;
      border: none;
      color: #373a3c;
      display: block;
      line-height: 6rem;
      padding: 0 1rem;
      text-decoration: none;
      text-transform: uppercase;
      transition: background 300ms, color 300ms; }
      #site-header nav a:hover,
      #site-header nav .nav-link-button:hover {
        background: #27a570;
        color: #fff; }

.page-header {
  border-bottom: 1px solid #ddd;
  margin-bottom: 2.4rem; }
  .page-header p {
    font-size: 1.8rem; }

.site-footer {
  border-top: 1px solid #ddd;
  color: #999;
  font-size: 1.2rem;
  margin: 6.4rem auto 0;
  max-width: 78rem;
  padding: 1.2rem 0 3.2rem;
  text-align: center;
  width: 85%; }

#entry-form-container .form {
  padding-bottom: 6.4rem; }
  #entry-form-container .form label {
    display: block;
    font-size: 1.4rem;
    margin-bottom: 0.25rem; }
    #entry-form-container .form label .required {
      color: #BE1045; }
  #entry-form-container .form .DayPickerInput {
    display: block;
    position: relative;
    width: 100%;
    z-index: 5; }

#entry-form-container .form-row {
  margin-left: -1.6rem;
  margin-right: -1.6rem; }
  #entry-form-container .form-row::after {
    clear: both;
    content: "";
    display: block; }
  #entry-form-container .form-row + h2 {
    margin-top: 2.4rem; }
  #entry-form-container .form-row + p {
    margin-top: 2.4rem; }

#entry-form-container .form-column {
  float: left;
  padding: 0 1.6rem 2.4rem;
  width: 100%; }
  #entry-form-container .form-column.form-column-20 {
    width: 20%; }
  #entry-form-container .form-column.form-column-25 {
    width: 25%; }
  #entry-form-container .form-column.form-column-33 {
    width: 33.33333%; }
  #entry-form-container .form-column.form-column-50 {
    width: 50%; }

#entry-form-container input:read-only {
  background: #ddd;
  font-weight: 700; }

.form-group-html-input.form-group-has-error input {
  border-color: #BE1045; }

.form-group-html-input .form-group-error-message {
  color: #BE1045;
  display: block;
  font-size: 1.2rem;
  padding-top: 0.25rem;
  text-transform: uppercase; }

#login {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh; }

#login-box {
  align-items: center;
  background: #fff;
  box-shadow: 0 0.25rem 0.5rem #ddd;
  display: flex;
  flex-direction: column;
  max-width: 36rem;
  padding: 3.2rem;
  width: 85%; }
  #login-box img {
    margin-bottom: 1.6rem; }
  #login-box .error, #login-box .reset {
    background: #BE1045;
    color: #fff;
    margin-bottom: 1.6rem;
    padding: 0.6rem 1.2rem;
    width: 100%; }
  #login-box .reset {
    background: #27a570; }
  #login-box .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.6rem;
    width: 100%; }
  #login-box p {
    margin: 0; }
  #login-box button.button-text {
    color: #27a570;
    margin-top: 3.2rem;
    padding: 0;
    transition: color 300ms; }
    #login-box button.button-text:hover {
      color: #1d7c54; }

.logs-filters-row {
  margin-left: -1.6rem;
  margin-right: -1.6rem;
  position: relative;
  z-index: 10; }
  .logs-filters-row::after {
    clear: both;
    content: "";
    display: block; }

.logs-filters-column {
  float: left;
  padding-bottom: 3.2rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  width: 33.33333%; }
  .logs-filters-column .DayPickerInput {
    display: block; }
  .logs-filters-column.logs-filters-column-50 {
    width: 50%; }
  .logs-filters-column.logs-filters-column-25 {
    width: 25%; }

.log-results ol {
  list-style: none;
  margin: 0; }
  .log-results ol > li {
    background: #fff;
    position: relative;
    transition: box-shadow 300ms; }
    .log-results ol > li:nth-child(even) {
      background: #ddd; }
    .log-results ol > li.txn-details-open {
      box-shadow: 0 0.25rem 0.5rem #999;
      z-index: 1; }
      .log-results ol > li.txn-details-open .top {
        padding: 2.4rem 1.2rem; }
      .log-results ol > li.txn-details-open .details {
        max-height: 100rem;
        padding: 2.4rem 1.2rem; }

.log-results ul {
  column-count: 2;
  column-gap: 1.6rem;
  list-style: none;
  margin: 0; }

.log-results .top {
  cursor: pointer;
  padding: 1.2rem;
  transition: all 300ms; }
  .log-results .top::after {
    clear: both;
    content: "";
    display: block; }
  .log-results .top:hover {
    background: #b9d33e; }

.log-results h2 {
  float: left;
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0; }

.log-results .txn-date {
  float: right;
  font-size: 1.4rem; }

.log-results .details {
  background: #eaeaea;
  font-size: 1.4rem;
  height: auto;
  overflow: hidden;
  max-height: 0;
  padding: 0 1.2rem;
  transition: all 300ms; }
  .log-results .details p {
    font-weight: 700;
    margin: 1.6rem 0 0;
    text-align: right; }

.report-filters-row {
  margin-left: -1.6rem;
  margin-right: -1.6rem;
  position: relative;
  z-index: 10; }
  .report-filters-row::after {
    clear: both;
    content: "";
    display: block; }

.report-filters-column {
  float: left;
  padding-bottom: 3.2rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  width: 50%; }
  .three-col .report-filters-column {
    width: 25%; }
    .three-col .report-filters-column.report-filters-column-wide {
      width: 50%; }
  .report-filters-column .DayPickerInput {
    display: block; }

#reports .reports-cards-list {
  list-style: none;
  margin-left: -1.6rem;
  margin-right: -1.6rem; }
  #reports .reports-cards-list::after {
    clear: both;
    content: "";
    display: block; }
  #reports .reports-cards-list li {
    display: block;
    float: left;
    padding: 0 1.6rem 3.2rem;
    width: 50%; }
  #reports .reports-cards-list a {
    background: #fff;
    box-shadow: 0 0.25rem 0.5rem #ddd;
    color: #373a3c;
    display: block;
    padding: 4.0rem 3.2rem;
    text-align: center;
    text-decoration: none;
    transition: all 300ms; }
    #reports .reports-cards-list a:hover {
      background: #27a570;
      box-shadow: 0 0.5rem 1rem #999;
      color: #fff; }
  #reports .reports-cards-list h2 {
    font-size: 1.8rem;
    margin: 0; }
  #reports .reports-cards-list p {
    font-size: 1.4rem;
    margin: 0; }

.report-general-list {
  list-style: none;
  margin-left: -1.6rem;
  margin-right: -1.6rem; }
  .report-general-list::after {
    clear: both;
    content: "";
    display: block; }
  .report-general-list > li {
    float: left;
    padding: 0 1.6rem 3.2rem;
    width: 50%; }
  .report-general-list .report-general-card {
    background: #fff;
    box-shadow: 0 0.25rem 0.5rem #ddd; }
    .report-general-list .report-general-card ul {
      list-style: none;
      margin: 0; }
      .report-general-list .report-general-card ul li {
        border-top: 1px solid #eaeaea;
        padding: 0.8rem 1.6rem; }
        .report-general-list .report-general-card ul li::after {
          clear: both;
          content: "";
          display: block; }
      .report-general-list .report-general-card ul .title {
        float: left;
        width: 55%; }
      .report-general-list .report-general-card ul .value {
        float: right;
        text-align: right;
        width: 40%; }
  .report-general-list h2 {
    background: #27a570;
    color: #fff;
    font-size: 1.8rem;
    padding: 0.8rem 1.6rem;
    margin: 0; }

.report-detailed-table thead th {
  font-size: 1.4rem;
  padding: 0.8rem; }
  .report-detailed-table thead th.data-col {
    text-align: right;
    padding: 0.8rem 0.4rem;
    width: 14%; }

.report-detailed-table tbody {
  background: #fff;
  border-top: 0.5rem solid #ddd;
  margin-top: 3.6rem; }
  .report-detailed-table tbody + thead th.data-col {
    padding-top: 3.2rem; }
  .report-detailed-table tbody tr:nth-child(even) {
    background: rgba(185, 211, 62, 0.25); }
  .report-detailed-table tbody th {
    border-bottom: 0.125rem solid #ddd;
    font-size: 1.6rem;
    padding: 0.4rem 0.8rem;
    text-align: left; }
  .report-detailed-table tbody td {
    font-size: 1.5rem;
    padding: 0.4rem 0.8rem; }
  .report-detailed-table tbody .data-col {
    border-left: 0.125rem solid #ddd;
    padding: 0.4rem;
    text-align: right;
    width: 14%; }

.report-ranking-table {
  margin-top: 1.5em; }

.pretty-select {
  position: relative; }
  .pretty-select button {
    border: none;
    font-size: 1.6rem;
    display: block;
    outline: 1px solid #ddd;
    padding: 1.2rem;
    text-align: left;
    width: 100%; }
  .pretty-select p {
    margin: 0;
    position: relative;
    z-index: 2; }
    .pretty-select p::before {
      border-left: 0.75rem solid transparent;
      border-right: 0.75rem solid transparent;
      border-top: 0.75rem solid #373a3c;
      content: '';
      display: block;
      height: 0.75rem;
      pointer-events: none;
      right: 1.6rem;
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      transition: transform 150ms;
      width: 0.75rem;
      z-index: 1; }
  .pretty-select ol {
    box-shadow: 0 0.25rem 0.25rem #ddd;
    left: 0;
    list-style: none;
    margin: 0;
    min-width: 100%;
    position: absolute;
    top: 100%;
    z-index: 1; }
  .pretty-select li {
    height: auto;
    overflow: hidden;
    max-height: 0;
    transition: max-height 150ms linear; }
    .pretty-select li button {
      background: #b5edd6;
      border: none;
      border-top: 0.125rem solid #eaeaea;
      padding-bottom: 0;
      padding-top: 0;
      pointer-events: none;
      transition: all 300ms; }
      .pretty-select li button:hover {
        background: #27a570;
        color: #fff; }
  .pretty-select.open p::before {
    transform: translateY(-50%) rotate(-180deg); }
  .pretty-select.open ol {
    z-index: 20; }
  .pretty-select.open li {
    max-height: 5rem; }
    .pretty-select.open li button {
      padding-bottom: 1.2rem;
      padding-top: 1.2rem;
      pointer-events: auto; }
